import { z } from 'zod';

/** Session rate dto validation schema. */
export const sessionRateDtoSchema = z.object({
	/** ID. */
	id: z.number(),

	/** Template ID. */
	template: z.number(),

	/** Session type. */
	session_type: z.string(),

	/** Session duration in minutes. */
	duration: z.number(),

	/** Session price. */
	rate: z.number().nullable(),

	/** Can other users offer custom price or not. */
	allow_offered: z.boolean(),

	/** Note. */
	note: z.string(),
});

/** Session rate. */
export type SessionRateDto = z.infer<typeof sessionRateDtoSchema>;

type SessionRateUpdateDto = Readonly<Pick<SessionRateDto, 'id' | 'allow_offered' | 'rate' | 'note'>>;

/** Consultation rates update dto. */
export type SessionRatesUpdateDto = {

	/** Rates. */
	readonly rates: readonly SessionRateUpdateDto[];

};
