import { z } from 'zod';

/**
 * Utility schema to prepare a schema for list API response.
 * @param schema Schema for list items.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- Let TypeScript infer the return type.
export function getListSchema<T>(schema: z.ZodSchema<T>) {
	return z.object({
		results: z.array(schema),
	});
}
