import { z } from 'zod';

import { UserDto } from '../user.dto';

/** Validation schema for chat dto. */
export const chatSchemaDto = z.object({

	/** Conversation security identifier. */
	conversation_sid: z.string(),

	/** Chat ID. */
	id: z.number(),
}).readonly();

/** Chat dto. */
export type ChatDto = z.infer<typeof chatSchemaDto>;

/** Data to request chat SID. */
export type ChatSidRequestDto = {

	/** ID of a user to chat with. */
	with_user: UserDto['id'];
};
