import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';

import { z } from 'zod';

import { choicesSchema } from '../dtos/choice.dto';
import { SessionDto, sessionDtoSchema } from '../dtos/session/session.dto';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { SessionMapper } from '../mappers/session.mapper';
import { ConsultationRequest } from '../models/consultation-request';
import { SessionType } from '../models/consultation-type';
import { Session, SessionUpdate } from '../models/session';
import { AppUrlsConfig } from '../services/app-urls.config';
import { SessionRate } from '../models/session-rate';
import { getListSchema } from '../dtos/list.dto';
import { sessionRateDtoSchema } from '../dtos/session/session-rate.dto';
import { SessionRateMapper } from '../mappers/session-rate.mapper';
import { Pagination } from '../models/pagination';
import { PaginationDto, createPaginationDtoSchemaParser } from '../dtos/pagination.dto';
import { PaginationMapper } from '../mappers/pagination.mapper';
import { NotFoundError } from '../models/app-error';
import { BaseFilterParams } from '../models/base-filter-params';
import { composeHttpParams } from '../utils/compose-http-params';
import { BaseFilterParamsMapper } from '../mappers/base-filter-params.mapper';
import { checkoutSessionDtoSchema } from '../dtos/checkout-session.dto';
import { chatSchemaDto } from '../dtos/chat/chat.dto';
import { CheckoutSession } from '../models/payment/checkout-session';
import { CheckoutSessionMapper } from '../mappers/payment/checkout-session.mapper';

/** API service to work with consultations. */
@Injectable({ providedIn: 'root' })
export class ConsultationsApiService {
	private readonly sessionMapper = inject(SessionMapper);

	private readonly sessionRateMapper = inject(SessionRateMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly http = inject(HttpClient);

	private readonly appUrlsConfig = inject(AppUrlsConfig);

	private readonly sessionRatesSchema = getListSchema(sessionRateDtoSchema);

	private readonly paginationMapper = inject(PaginationMapper);

	private readonly baseFilterParamsMapper = inject(BaseFilterParamsMapper);

	private readonly checkoutSessionMapper = inject(CheckoutSessionMapper);

	/**
	 * Get list of consultation.
	 * @param options Filter options.
	 */
	public getConsultations(options: BaseFilterParams.Combined): Observable<Pagination<Session>> {
		const params = composeHttpParams({
			...this.baseFilterParamsMapper.mapCombinedOptionsToDto(options),

			// (Tien Luu): For now, this list response is always want to be sorted by -created.
			// Create new filter mapper if there are new requirements about ordering.
			ordering: '-created',
		});

		return this.http.get<PaginationDto<SessionDto>>(this.appUrlsConfig.consultations.base, { params }).pipe(
			map(response => createPaginationDtoSchemaParser(sessionDtoSchema).parse(response)),
			map(response => this.paginationMapper.mapPaginationFromDto(response, this.sessionMapper)),
		);
	}

	/** Get list of available consultation types. */
	public getConsultationTypes(): Observable<SessionType[]> {
		return this.http.get<unknown>(this.appUrlsConfig.constants.sessionTypes)
			.pipe(
				map(response => choicesSchema.parse(response).results),
				map(types => types.map<SessionType>(t => ({ id: t.value, label: t.label }))),
			);
	}

	/**
	 * Request a consultation.
	 * @param data Consultation information.
	 * @returns Session ID.
	 */
	public requestConsultation(data: ConsultationRequest): Observable<number> {
		const body = this.sessionMapper.toCreateDto(data);
		const sessionDtoIdSchema = z.object({ id: z.number() });

		return this.http.post<unknown>(this.appUrlsConfig.consultations.base, body)
			.pipe(

				/*
					A little bit of context. API endpoint returns a short session DTO.
					For the task I'm working on I don't need the whole model but ID.
					Instead of asking backend team to update response or add a new schema I'm using zod to parse only ID.
					If you need this method to return a full session, it's better to contact backend team
					and ask them to update the response.
				*/
				map(response => sessionDtoIdSchema.parse(response)),
				map(sessionDto => sessionDto.id),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
					this.sessionMapper,
				),
			);
	}

	/**
	 * Get list of consultation rates for user.
	 * @param id User id.
	 */
	public getUserConsultationRates(id: number): Observable<SessionRate[]> {
		return this.http.get<unknown>(this.appUrlsConfig.consultations.rates(id)).pipe(
			map(response => this.sessionRatesSchema.parse(response).results),
			map(ratesDto => ratesDto.map(rateDto => this.sessionRateMapper.fromDto(rateDto))),
		);
	}

	/**
	 * Get session information by id.
	 * @param id Session ID.
	 */
	public getSession(id: Session['id']): Observable<Session> {
		return this.http.get<unknown>(this.appUrlsConfig.consultations.entity(id)).pipe(
			map(response => sessionDtoSchema.parse(response)),
			map(sessionDto => this.sessionMapper.fromDto(sessionDto)),
			catchError((error: unknown) => {
				// TODO: Add interceptor for 404 error.
				if (error instanceof HttpErrorResponse && error.status === 404) {
					return throwError(() => new NotFoundError('Session not found'));
				}
				return throwError(() => error);
			}),
		);
	}

	/**
	 * Update session.
	 * @param session Session.
	 */
	public updateSession(session: SessionUpdate): Observable<void> {
		const body = this.sessionMapper.toUpdateDto(session);
		return this.http.put<unknown>(this.appUrlsConfig.consultations.entity(session.id), body).pipe(
			map(_ => undefined),
		);
	}

	/**
	 * Make payment for consultation.
	 * @param id Consultation ID.
	 */
	public preparePaymentData(id: Session['id']): Observable<CheckoutSession> {
		return this.http.post<unknown>(this.appUrlsConfig.consultations.checkout(id), {}).pipe(
			map(response => checkoutSessionDtoSchema.parse(response)),
			map(dto => this.checkoutSessionMapper.fromDto(dto)),
		);
	}

	/**
	 * Get chat SID of a consultation.
	 * @param id Consultation ID.
	 */
	public getConsultationChatSid(id: Session['id']): Observable<string> {
		return this.http.get<unknown>(this.appUrlsConfig.consultations.chat(id)).pipe(
			map(response => chatSchemaDto.parse(response).conversation_sid),
		);
	}

	/**
	 * Rate consultation.
	 * @param id Consultation ID.
	 * @param rating Rating value.
	 */
	public rateConsultation(id: number, rating: number): Observable<void> {
		const body = { rating };
		return this.http.post<unknown>(this.appUrlsConfig.consultations.rate(id), body).pipe(
			map(_ => undefined),
		);
	}

	/**
	 * Get guidelines information.
	 * @returns Stream that emits HTML string from WYSIWYG.
	 */
	public getGuidelines(): Observable<string> {
		const schema = z.object({
			guidelines: z.string(),
		});

		return this.http.post<unknown>(this.appUrlsConfig.consultations.guidelines, undefined).pipe(
			map(response => schema.parse(response)),
			map(dto => dto.guidelines),
		);
	}
}
